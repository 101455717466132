import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { BRAND_ID_PARAM, ROUTE_GIFT_CARDS } from '../../config/routes';
import {
  getActionType,
  getEventName,
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  POSTHOG_PROPERTIES,
  TRACKER_IDS,
} from '../../config/tracker';
import { REWARD_AMOUNT_TYPES_ID } from '../../config/rewards';
import { TAKE_BACK_BAG_URL } from '../../config/externalURL';
import { getGiftCardBrand, issueGiftCard } from '../../utils/service';
import ArrowBack from '../../images/circle-arrow-left-gray.svg';
import TCCoin from '../../images/TC-icon.svg';
import LoadingBar from '../../global/LoadingBar';
import GeneralModal from '../../global/GeneralModal';
import StickyButton from '../../global/StickyButton/StickyButton';
import {
  RewardContentError,
  RewardContentLoading,
} from '../Reward/RewardContent';
import { isMobile } from '../../../../hooks/useBreakPoint';
import { GiftCardContentMain, GiftCardContentSuccess, GiftCardContentTerms } from './GiftCardContent';
import { getGiftCardBrandValues } from '../../utils/giftCards';

import './GiftCardBrand.scss';

const {
  giftCards: {
    brandPage: {
      confirmButton: trackerConfirmButton,
    },
  },
} = TRACKER_IDS;

const MAIN_ELEMENT_ID = 'GiftCardBrand';

const VIEW_STATE = {
  LOADING: 'loading',
  AVAILABLE: 'available',
  ERROR: 'error',
  TERMS: 'terms',
};

const MODAL_STATE = {
  LOADING: 'loading',
  ERROR: 'error',
  TERMS: 'terms',
  FINAL: 'final',
};

const GiftCardBrand = () => {
  const { userData, isLoading: isLoadingFirebaseAuth, isSignedIn, user } = useFirebaseAuth();
  const posthog = usePostHog();
  const {
    [BRAND_ID_PARAM]: brandId,
  } = useParams();
  const history = useHistory();
  const timeoutRef = useRef(null);
  const mobile = isMobile();

  const [brand, setBrand] = useState({});
  const [value, setValue] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [viewState, setViewState] = useState(VIEW_STATE.LOADING);
  const [modalState, setModalState] = useState(MODAL_STATE.LOADING);
  const [giftCard, setGiftCard] = useState({});

  const toggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const handleSelect = useCallback(newValue => {
    setValue(newValue);
  }, []);

  const scrollToTop = useCallback(() => {
    const mainElement = document.getElementById(MAIN_ELEMENT_ID).parentElement;

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, []);

  const {
    minDenomination,
    minBalance,
    disabled,
  } = useMemo(() => (
    getGiftCardBrandValues({
      giftCardBrand: brand,
      currentBalance,
    })
  ), [brand, currentBalance]);

  const loadGiftCardBrand = useCallback(async () => {
    setViewState(VIEW_STATE.LOADING);

    const {
      data,
      error: giftCardBrandError,
    } = await getGiftCardBrand(brandId);

    if (giftCardBrandError) {
      setBrand({});
      setValue(0);
      setCurrentBalance(0);
      setViewState(VIEW_STATE.ERROR);
      return;
    }

    const {
      balance: {
        currentBalance: currentBalanceData,
      },
    } = userData;

    const {
      minDenomination: initialValue,
    } = getGiftCardBrandValues({
      giftCardBrand: data.brand,
      currentBalance: currentBalanceData,
    });

    setBrand(data.brand);
    setValue(initialValue);
    setCurrentBalance(currentBalanceData);
    setViewState(VIEW_STATE.AVAILABLE);
    scrollToTop();
  }, [brandId, userData]);

  const handleSubmit = useCallback(async () => {
    setModalState(MODAL_STATE.LOADING);
    toggleModal();

    posthog.capture(
      getEventName(trackerConfirmButton),
      {
        [POSTHOG_PROPERTIES.REWARD_ID]: brand.uid,
        [POSTHOG_PROPERTIES.REWARD_NAME]: brand.name,
        ...(isSignedIn && user && ({
          $set: { email: user.email },
        })),
      },
    );

    const {
      data,
      error: claimGiftCardError,
    } = await issueGiftCard({
      uid: brand.uid,
      brand: brand.slug,
      amount: value,
    });

    if (claimGiftCardError) {
      setGiftCard({});
      setModalState(VIEW_STATE.ERROR);
      return;
    }

    setGiftCard(data.giftCard);
    setModalState(MODAL_STATE.FINAL);
  }, [brand, isSignedIn, user, value, toggleModal]);

  const getGiftCardContent = useCallback(state => {
    switch (state) {
      case VIEW_STATE.AVAILABLE:
        return (
          <GiftCardContentMain
            giftCardBrandItem={brand}
            selectedValue={value}
            onSelect={handleSelect}
            onSubmit={handleSubmit}
            onShowTerms={() => {
              setModalState(MODAL_STATE.TERMS);
              toggleModal();
            }}
            currentBalance={currentBalance}
            submitDisabled={disabled}
            minBalance={minBalance}
            minDenomination={minDenomination}
            trackerProps={{ ...!disabled && {
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerConfirmButton),
              [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerConfirmButton),
            } }}
          />
        );
      case VIEW_STATE.ERROR:
        return (
          <LoadingBar />
        );
      case VIEW_STATE.LOADING:
      default:
        return <LoadingBar />;
    }
  }, [
    brand,
    currentBalance,
    value,
    disabled,
    handleSelect,
    handleSubmit,
    toggleModal,
  ]);

  const getModalContent = useCallback(() => {
    switch (modalState) {
      case MODAL_STATE.LOADING:
        return (
          <RewardContentLoading
            rewardItem={{
              amountType: REWARD_AMOUNT_TYPES_ID.VALUE,
              rewardAmount: value,
              store: {
                title: brand.name,
              },
            }}
          />
        );
      case MODAL_STATE.ERROR:
        return (
          <RewardContentError
            onBack={toggleModal}
            isGiftCard
          />
        );
      case MODAL_STATE.TERMS:
        return (
          <GiftCardContentTerms
            giftCardBrandItem={brand}
            onClose={toggleModal}
          />
        );
      case MODAL_STATE.FINAL:
        return (
          <GiftCardContentSuccess
            giftCardBrandItem={brand}
            giftCard={giftCard}
            onFinish={toggleModal}
          />
        );
      default:
        return null;
    }
  }, [modalState, brand, giftCard, toggleModal]);

  const handleBackToUrl = useCallback(() => {
    history.push(ROUTE_GIFT_CARDS);
  }, [history]);

  const {
    buttonLabel,
    buttonOnClick,
    stickySectionSubtitle,
  } = useMemo(() => {
    if (disabled) {
      return {
        buttonLabel: 'BUY A TAKE BACK BAG',
        buttonOnClick: () => {
          window.location.href = TAKE_BACK_BAG_URL;
        },
        stickySectionSubtitle: (
          <>
            <span>You need more </span>
            <img src={TCCoin} alt="TCH" />
          </>
        ),
      };
    }

    return {
      buttonLabel: 'GET THIS GIFT CARD',
      buttonOnClick: handleSubmit,
      stickySectionSubtitle: `$${value} Gift Card`,
    };
  }, [disabled, viewState, value, brand, history, handleSubmit]);

  useEffect(() => {
    if (!isLoadingFirebaseAuth && userData && brandId) {
      loadGiftCardBrand();
    }
  }, [isLoadingFirebaseAuth, userData, brandId]);

  useEffect(() => (
    () => timeoutRef?.current && clearTimeout(timeoutRef.current)
  ), []);

  return (
    <div id={MAIN_ELEMENT_ID} className="GiftCardBrand">
      <div className="GiftCardBrand__content">
        <div className="GiftCardBrand__content--container">
          {!mobile && (
            <button
              className="GiftCardBrand__content--button"
              type="button"
              onClick={handleBackToUrl}
            >
              <img src={ArrowBack} alt="arrow-back" />
              GO TO ALL GIFT CARDS
            </button>
          )}
          <div className="GiftCardBrand__content--section">
            {viewState === VIEW_STATE.LOADING ? (
              <LoadingBar className="GiftCardBrand__content--loader" />
            ) : (
              getGiftCardContent(viewState)
            )}
          </div>
        </div>
      </div>
      {viewState !== VIEW_STATE.LOADING && viewState !== VIEW_STATE.ERROR && (
        <StickyButton
          buttonLabel={buttonLabel}
          onSubmit={buttonOnClick}
          title={viewState !== VIEW_STATE.FINAL && !disabled ? brand.name : null}
          subTitle={stickySectionSubtitle}
          showDescription={viewState !== VIEW_STATE.FINAL}
          typeSecondary={disabled}
          trackerProps={{ ...!disabled && {
            [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerConfirmButton),
            [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerConfirmButton),
          } }}
        />
      )}
      <GeneralModal
        onClose={toggleModal}
        showModal={showModal}
        showModalHeader={false}
        showModalFooter={false}
        canClose={modalState === MODAL_STATE.TERMS}
        showClose={modalState === MODAL_STATE.TERMS}
      >
        {getModalContent()}
      </GeneralModal>
    </div>
  );
};

export default GiftCardBrand;
