import GiftCardItem from '../GiftCardItem';

import './GiftCardsList.scss';

const GiftCardsList = ({
  giftCardsList,
  onClick,
}) => (
  <div className="GiftCardsList">
    {giftCardsList.map(giftCardItem => (
      <GiftCardItem
        key={`giftCard-${giftCardItem.uid}`}
        giftCardItem={giftCardItem}
        onClick={onClick}
      />
    ))}
  </div>
);

export default GiftCardsList;
