import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material';

const StyledCheckbox = styled((props) => <Checkbox {...props} size="small" />)(({ checkedColor }) => ({
  color: '#1B1A1B',
  padding: '2px',
  '&.Mui-checked': {
    color: checkedColor ?? '#1B1A1B',
  },
}));

export default StyledCheckbox;
