import { useState, useCallback } from 'react';
import FormControlLabel from '../../../../global/Mui/FormControlLabel';
import Checkbox from '../../../../global/Mui/Checkbox';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';

import './DeviceBatteryWarning.scss';

const DeviceBatteryWarning = ({ onSubmit, onCancel }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = useCallback((event) => {
    setIsChecked(event.target.checked);
  }, [isChecked]);

  return (
    <div className="DeviceBatteryWarning">
      <div className="DeviceBatteryWarning__container">
        <div className="DeviceBatteryWarning__container--title">
          DOES YOUR DEVICE MEET THE FOLLOWING requirements?
        </div>
        <div className="DeviceBatteryWarning__container--content">
          <div className="DeviceBatteryWarning__container--content--text">
            <ul>
              <li>The battery has not been removed.</li>
              <li>The battery is not visible or loose.</li>
              <li>The battery is not damaged, leaking, or swollen.</li>
            </ul>
          </div>
          <div className="DeviceBatteryWarning__container--content--checkbox">
            <FormControlLabel
              label="Yes, it meets these requirements"
              control={(
                <Checkbox
                  checkedColor="#2649D4 !important"
                  onChange={handleCheckboxChange}
                />
            )}
            />
          </div>
          <Button
            type={BUTTON_TYPE.QUATERNARY}
            size={BUTTON_SIZE.LARGE}
            disabled={!isChecked}
            onClick={onSubmit}
          >
            continue
          </Button>
        </div>
        <div className="DeviceBatteryWarning__container--contact">
          Need assitance?
          <Button
            type={BUTTON_TYPE.LINK_QUATERNARY}
            size={BUTTON_SIZE.SMALL}
            onClick={onCancel}
          >
            CONTACT SUPPORT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeviceBatteryWarning;
