import { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTE_GIFT_CARDS, GIFT_CARD_ID_PARAM } from '../../config/routes';
import { getGiftCardByUid } from '../../utils/service';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../global/Button';
import TermsDisclaimer from '../../global/TermsDisclaimer';
import ArrowBack from '../../images/arrow-back.svg';
import LoadingBar from '../../global/LoadingBar';
import GeneralModal from '../../global/GeneralModal';
import { getActionType, getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../config/tracker';
import { isMobile } from '../../../../hooks/useBreakPoint';
import { GiftCardCard, GiftCardContentSummary, GiftCardContentTerms, GiftCardCover } from '../GiftCardBrand/GiftCardContent';

import './GiftCardInfo.scss';
import { getGiftCardUniqueUrl } from '../../utils/giftCards';

const {
  giftCards: {
    detail: {
      imageLink: trackerImageLink,
      shopButton: trackerShopButton,
    },
  },
} = TRACKER_IDS;

const GIFT_CARD_INFO_ID = 'giftCardInfo';

const GiftCardInfo = () => {
  const { [GIFT_CARD_ID_PARAM]: giftCardId } = useParams();
  const history = useHistory();
  const [giftCardItem, setGiftCardItem] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const timeoutRef = useRef(null);
  const mobile = isMobile();

  const toggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const loadGiftCard = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: giftCardDataError,
    } = await getGiftCardByUid(giftCardId);

    if (giftCardDataError) {
      setGiftCardItem({});
    }

    const {
      name,
      logoUrl,
      termsCopy,
    } = data.giftCard?.giftCardOrder?.giftCardBrand || {};

    setGiftCardItem({
      name,
      logoUrl,
      termsCopy,
      ...data.giftCard,
    });

    setIsLoading(false);
  }, [giftCardId]);

  const scrollToTop = useCallback(() => {
    const mainElement = document.getElementById(GIFT_CARD_INFO_ID).parentElement;

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, []);

  useEffect(() => {
    loadGiftCard();
    scrollToTop();

    return () => timeoutRef?.current && clearTimeout(timeoutRef.current);
  }, []);

  return (
    <div className="GiftCardInfo" id={GIFT_CARD_INFO_ID}>
      <div className="GiftCardInfo__content">
        <div className="GiftCardInfo__content--container">
          {!mobile && (
            <Button
              type={BUTTON_TYPE.SECONDARY}
              size={BUTTON_SIZE.MEDIUM}
              className="GiftCardInfo__content--button"
              onClick={() => (
                history.length > 1 ? history.goBack() : history.push(ROUTE_GIFT_CARDS)
              )}
            >
              <img src={ArrowBack} alt="arrow-back" />
            </Button>
          )}
          <div className="GiftCardInfo__content--section">
            { isLoading ? (
              <LoadingBar className="GiftCardInfo__content--loader" />
            ) : (
              <>
                <GiftCardCover
                  logoUrl={giftCardItem.logoUrl}
                  uniqueUrl={getGiftCardUniqueUrl(giftCardItem)}
                  trackerProps={{
                    [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerImageLink),
                    [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerImageLink),
                  }}
                />
                <div className="GiftCardInfo__content--message">
                  <p className="GiftCardInfo__content--message-title">
                    {`YOU SCORED A $${giftCardItem?.amount} GIFT CARD!`}
                  </p>
                  <p className="GiftCardInfo__content--message-desc">
                    <span>You must use this </span>
                    <a href={giftCardItem.url}>unique link</a>
                    <span>. At checkout, use your code: </span>
                    <a href={giftCardItem.url}>CODE HERE</a>
                  </p>
                </div>
                <a
                  className="GiftCardInfo__content--cta"
                  href={giftCardItem.url}
                  {...{
                    [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerShopButton),
                    [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerShopButton),
                  }}
                >
                  SHOP NOW
                </a>
                <div className="GiftCardInfo__content--message">
                  <p className="GiftCardInfo__content--message-title">
                    GIFT CARD DETAILS
                  </p>
                  <GiftCardCard giftCardItem={giftCardItem} />
                </div>
                <GiftCardContentSummary giftCardBrandItem={giftCardItem} />
                <TermsDisclaimer
                  terms={giftCardItem.termsCopy}
                  ctaText="VIEW ALL TERMS"
                  termsTitle="MERCHANT TERMS"
                  onHandleClick={toggleModal}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="GiftCardInfo__ctaContainer">
        <a
          className="cta-shop"
          href={giftCardItem.affiliateLink || giftCardItem.store?.url}
          {...{
            [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerShopButton),
            [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerShopButton),
          }}
        >
          SHOP NOW
        </a>
      </div>
      <GeneralModal
        onClose={toggleModal}
        showModal={showModal}
        showModalHeader={false}
        showModalFooter={false}
        canClose
        showClose
      >
        <GiftCardContentTerms
          giftCardBrandItem={giftCardItem}
          onClose={toggleModal}
        />
      </GeneralModal>
    </div>
  );
};

export default GiftCardInfo;
