import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { REWARD_AMOUNT_TYPES, REWARD_AMOUNT_TYPES_ID } from '../../../config/rewards';
import { GIFT_CARD_ID_ROUTE_PARAM, ROUTE_GIFT_CARD_INFO, ROUTE_GIFT_CARDS } from '../../../config/routes';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../global/Button';
import GiftCardIcon from '../../../images/gift-card-icon.svg';
import MailSendIcon from '../../../images/mail-send-icon.svg';
import TimerIcon from '../../../images/timer-icon.svg';
import WalletIcon from '../../../images/wallet-icon.svg';

import './GiftCardContent.scss';

const MESSAGE_ITEMS = [
  {
    id: 'message-email',
    icon: MailSendIcon,
    label: 'You’ll receive your gift card details via email',
  },
  {
    id: 'message-time',
    icon: TimerIcon,
    label: 'Gift cards may take up to 15 minutes to be delivered',
  },
  {
    id: 'message-wallet',
    icon: WalletIcon,
    label: 'It will be added to your wallet',
  },
];

const GiftCardContentSuccess = ({
  giftCardBrandItem,
  giftCard,
}) => {
  const history = useHistory();
  const {
    name,
  } = useMemo(() => (
    giftCardBrandItem
  ), [giftCardBrandItem]);

  const {
    uid,
    face_value: {
      amount,
    },
  } = useMemo(() => (
    giftCard
  ), [giftCard]);

  const handleOnView = useCallback(() => {
    history.push(ROUTE_GIFT_CARD_INFO.replace(GIFT_CARD_ID_ROUTE_PARAM, uid));
  }, [history, uid]);

  const handleOnBack = useCallback(() => {
    history.push(ROUTE_GIFT_CARDS);
  }, [history]);

  return (
    <div className="GiftCardContentSuccess">
      <div className="GiftCardContentSuccess__icon">
        <img
          src={GiftCardIcon}
          alt="gift card icon"
        />
      </div>
      <div className="GiftCardContentSuccess__title">
        THIS GIFT CARD IS YOURS NOW!
      </div>
      <div className="GiftCardContentSuccess__desc">
        {REWARD_AMOUNT_TYPES[REWARD_AMOUNT_TYPES_ID.VALUE]?.getText(amount)}
        {` to ${name}`}
      </div>
      <div className="GiftCardContentSuccess__actions">
        {MESSAGE_ITEMS.map(({ id, icon: Icon, label }) => (
          <div
            key={id}
            className="GiftCardContentSuccess__actions-action"
          >
            <img
              src={Icon}
              alt="store"
            />
            <span className="GiftCardContentSuccess__actions-action-name">
              {label}
            </span>
          </div>
        ))}
      </div>
      <Button
        type={BUTTON_TYPE.PRIMARY}
        size={BUTTON_SIZE.LARGE}
        onClick={handleOnView}
      >
        VIEW GIFT CARD IN WALLET
      </Button>
      <Button
        type={BUTTON_TYPE.SECONDARY}
        size={BUTTON_SIZE.LARGE}
        onClick={handleOnBack}
      >
        BROWSE OTHER GIFT CARDS
      </Button>
    </div>
  );
};

export default GiftCardContentSuccess;
