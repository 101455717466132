import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CopyButton from '../../../global/CopyButton';
import {
  getActionType,
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  TRACKER_IDS,
} from '../../../config/tracker';
import { getGiftCardUniqueUrl } from '../../../utils/giftCards';

import './GiftCardContent.scss';

const COPY_DATA_TYPES = {
  CODE: 'code',
  PIN: 'pin',
};

const {
  giftCards: {
    detail: {
      copyCodeLink: trackerCopyCodeLink,
      copyPinLink: trackerCopyPinLink,
    },
  },
} = TRACKER_IDS;

const GiftCardCard = ({ giftCardItem }) => {
  const timeoutRef = useRef(null);

  const [copiedData, setCopiedData] = useState(null);

  const {
    code,
    pin,
    url,
    amount,
  } = useMemo(() => (
    giftCardItem
  ), [giftCardItem]);

  const uniqueUrl = getGiftCardUniqueUrl(giftCardItem);

  const handleCopy = useCallback((dataType) => {
    const data = dataType === COPY_DATA_TYPES.PIN ? pin : code;

    setCopiedData(dataType);

    navigator.clipboard.writeText(data);

    timeoutRef.current = setTimeout(() => {
      window.location.href = uniqueUrl;
    }, 500);
  }, [code, pin, uniqueUrl]);

  useEffect(() => (
    () => timeoutRef?.current && clearTimeout(timeoutRef.current)
  ), []);

  return (
    <div className="GiftCardCard">
      {(code || url) && (
        <div className="GiftCardCard__item">
          <p className="GiftCardCard__item--title">
            {code ? 'CODE' : 'MY GIFT CARD'}
          </p>
          <div className="GiftCardCard__item--data">
            <CopyButton
              className="GiftCardCard__item--data-button"
              data={code || (
                <a className="GiftCardCard__item--link" href={url}>
                  CLICK TO USE MY GIFT CARD
                </a>
              )}
              onClick={code ? () => handleCopy(COPY_DATA_TYPES.CODE) : undefined}
              copied={copiedData === COPY_DATA_TYPES.CODE}
              trackerProps={{
                [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCopyCodeLink),
                [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCopyCodeLink),
              }}
            />
          </div>
        </div>
      )}
      {pin && (
        <div className="GiftCardCard__item">
          <p className="GiftCardCard__item--title">PIN</p>
          <div className="GiftCardCard__item--data">
            <CopyButton
              className="GiftCardCard__item--data-button"
              data={(
                <a
                  className="GiftCardCard__item--link"
                  href={uniqueUrl}
                >
                  {pin}
                </a>
              )}
              onClick={() => handleCopy(COPY_DATA_TYPES.PIN)}
              copied={copiedData === COPY_DATA_TYPES.PIN}
              trackerProps={{
                [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCopyPinLink),
                [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCopyPinLink),
              }}
            />
          </div>
        </div>
      )}
      <div className="GiftCardCard__item">
        <p className="GiftCardCard__item--title">
          GIFT CARD
        </p>
        <p className="GiftCardCard__item--data-button">
          {`$${amount}`}
        </p>
      </div>
    </div>
  );
};

export default GiftCardCard;
