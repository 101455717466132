const getGiftCardRatio = (tcRatio) => {
  const [cash, tc] = (tcRatio ?? '1:2').split(':');
  return Number(cash) / Number(tc);
};

const getGiftCardBrandValues = ({
  giftCardBrand = {},
  currentBalance = 0,
}) => {
  const {
    tcRatio,
    denominations = [],
  } = giftCardBrand;

  const ratio = getGiftCardRatio(tcRatio);

  const minDenomination = denominations[0] ?? 0;
  const maxDenomination = denominations[denominations.length - 1] ?? 0;
  const minBalance = minDenomination / ratio;

  return {
    ratio,
    minDenomination,
    maxDenomination,
    minBalance,
    disabled: denominations?.length === 0 || currentBalance < minBalance,
  };
};

const getGiftCardUniqueUrl = ({ giftCardUrl, url = '', website = '' }) => (
  giftCardUrl || url || website
);

const getGiftCardBrandDenominationOptions = (denominations = []) => (
  denominations.map(value => ({
    uid: value,
    rewardAmount: String(value),
    exchangeValue: value,
  }))
);

export {
  getGiftCardRatio,
  getGiftCardBrandValues,
  getGiftCardUniqueUrl,
  getGiftCardBrandDenominationOptions,
};
