import './GiftCardContent.scss';

const SUMMARY_ITEMS = [
  {
    id: 'cancel',
    label: 'Claimed gift cards can’t be cancelled, changed, or exchanged.',
  },
  {
    id: 'refundable',
    label: 'The TrashieCash™ exchanged for a Gift Card is non-refundable.',
  },
  {
    id: 'merchant_terms',
    label: 'Please view the terms and conditions below for more details on merchant restrictions and exclusions.',
  },
  {
    id: 'trashie_terms',
    label: 'TrashieCash™ Terms and Conditions apply and are subject to change.',
  },
];

const GiftCardContentSummary = ({ title }) => (
  <div className="GiftCardContentSummary">
    {title ? (
      <p className="GiftCardContentSummary__title">{title}</p>
    ) : (
      <p className="GiftCardContentSummary__message">Please also consider:</p>
    )}
    <ul className="GiftCardContentSummary__list">
      {SUMMARY_ITEMS.map(({
        id,
        label,
      }) => (
        <li
          key={id}
          className="GiftCardContentSummary__list-message"
        >
          {label}
        </li>
      ))}
    </ul>
  </div>
);

export default GiftCardContentSummary;
