export const getNextButtonStyles = (storeConfig) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    background: registerTbbFlow?.nextButton?.bgColor,
    color: registerTbbFlow?.nextButton?.textColor,
    border: registerTbbFlow?.nextButton?.bgColor,
  };
};

export const getOptionButtonStyles = (storeConfig, border = false) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    color: registerTbbFlow?.nextButton?.bgColor,
    ...(border && { borderColor: registerTbbFlow?.nextButton?.bgColor }),
  };
};

export const REDEMPTION_CODE_STATUS_IDS = {
  CLAIMED: 1,
  RETURNED: 2,
  PENDING: 3,
  BLOCKED: 4,
};

export const getCouponStyle = (storeConfig) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    color: registerTbbFlow.rewardStep?.cardTextColor,
    background: registerTbbFlow.rewardStep?.cardBgColor,
    border: 'none',
  };
};

export const RECYCLING_PRODUCT_TYPES = {
  TEXTIL: 'TEXTILES_TBB',
  TECH: 'E_WASTE_TBB',
};

export const RECYCLING_PRODUCT_ITEMS = [
  { label: 'Amazon Alexa, Google Assistant' },
  { label: 'Cable box, Roku box, Apple TV' },
  { label: 'Cables, power cords, chargers' },
  { label: 'Cell or smart phone' },
  { label: 'Circuit boards' },
  { label: 'Digital Cameras' },
  { label: 'Laptop, Chomebook, or tablet' },
  { label: 'Modem, or router' },
];

export const RECYCLING_PRODUCT_ITEMS_NOT_ALLOWED = [
  'External loose batteries (household alkaline or loose lithium)',
  'Airpods or headphones',
  'Smartwatches',
  'Loose hard drives',
  'Keyboards & Mouse',
  'Inkjet Cartr',
  'Light Bulbs (ALL)',
  'Speakers, microphones',
  'Apple Airtags',
  'Toys',
  'Electronic Thermometer',
  'Electric Toothbrushes',
  'Hazardous Waste Pressurized canisters, Paint, Chemicals, Oils, etc.',
  'Medical / Biohazard Waste',
  'Smoke and Carbon monoxide detectors',
  'Radios',
  'Thermostats',
  'Clocks',
  'Kitchen Appliances',
  'Mixed magnetic tapes, CD/DVD, and DLT/LTO, Cassettes',
  'Calculators',
];

export const RECYCLING_BOX_VIDEO_URL = 'https://storage.googleapis.com/trashie-public-assets/videos/BATTERY-STICKERS/sticker-animation_1.mp4';
export const RECYCLING_BOX_STICKERS_URL = 'https://storage.googleapis.com/trashie-public-assets/pdfs/Lithium-Battery-Labels.pdf';
