/* eslint-disable no-unused-vars */
import { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

import GlobalLogin, { viewType } from '../../../global/GlobalLogin';
import { useFirebaseAuth } from '../../../../global/FirebaseProvider/FirebaseProvider';
import crud from '../../../../../api/crud';

import { useAlert } from '../../../../providers/AlertProvider';
import { getDecryptedData } from '../../../utils/crypto-utils';
import { finishProcess, SOURCE } from '../../../utils/login';

import {
  EMAIL_SEARCH_PARAM,
  REGISTER_OPTIN_PARAM,
  REGISTER_SIGNUP_PARAM,
  TYPE_SEARCH_PARAM,
  ROUTE_LOGIN,
  AUTH_TOKEN_SEARCH_PARAM,
} from '../../../config/routes';
import { getSearchParam } from '../../../utils/routes';
import { getGenericError } from '../../../utils/errors';
import {
  getViewType,
  getURIParam,
  getWildfireDeviceId,

} from '../../Login/utils';

import './Login.scss';
import { getConfig } from '../../../../../config/config';

const Login = () => {
  const { search } = useLocation();
  const { isSignedIn, isLoading: isLoadingFirebaseAuth, userData, signOut } = useFirebaseAuth();
  const setAlert = useAlert();
  const posthog = usePostHog();

  const query = new URLSearchParams(search);
  const uriParam = getURIParam(query);
  const wfedidParam = getWildfireDeviceId(query);
  const typeParam = getSearchParam(query, TYPE_SEARCH_PARAM);
  const emailParam = getSearchParam(query, EMAIL_SEARCH_PARAM);

  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState(getViewType(typeParam) || viewType.VIEW_EMAIL_FORM);
  const [finishProcessData, setFinishProcessData] = useState(null);

  const redirect = useCallback(async () => {
    setIsLoading(true);

    const {
      uid: userUid,
      error,
    } = userData;

    if (error) {
      setAlert({
        type: 'notification',
        message: getGenericError(),
        error,
      });

      setIsLoading(false);
      return;
    }

    const data = await getDecryptedData(query, userUid);
    await finishProcess({
      ...(finishProcessData && finishProcessData),
      ...(REGISTER_SIGNUP_PARAM in data && { userRegistered: data[REGISTER_SIGNUP_PARAM] }),
      ...(REGISTER_OPTIN_PARAM in data && { optIn: data[REGISTER_OPTIN_PARAM] }),
      userData,
      search,
      wfExtensionFlow: uriParam && wfedidParam,
      wfedidParam,
      posthog,
      source: SOURCE.EMBED,
    });

    try {
      const authToken = await crud.post({
        path: '/auth/custom-token',
      });
      await signOut();
      setFinishProcessData(null);
      window.open(`${getConfig('BASE_URL_TRASHIE')}${ROUTE_LOGIN}?${AUTH_TOKEN_SEARCH_PARAM}=${authToken}`);
      setView(viewType.VIEW_EMAIL_FORM);
    } catch (e) {
      setAlert({
        type: 'notification',
        message: getGenericError(),
        e,
      });
    }

    setIsLoading(false);
  }, [userData, search, isLoadingFirebaseAuth, isLoading, view, finishProcessData]);

  const handleOnViewChange = useCallback((currentView) => {
    setView(currentView);
  }, []);

  useEffect(() => {
    if (isSignedIn && userData && !isLoadingFirebaseAuth && finishProcessData) {
      redirect();
    }
  }, [isSignedIn && userData, isLoadingFirebaseAuth, finishProcessData]);

  return (
    <div className="EmbedLogin">
      <GlobalLogin
        isExternalEmbedded
        initialView={getViewType(typeParam)}
        initialEmail={emailParam}
        onViewChange={handleOnViewChange}
        isParentLoading={isLoadingFirebaseAuth}
        setFinishProcessData={setFinishProcessData}
        view={view}
        setView={setView}
      />
    </div>
  );
};

export default Login;
