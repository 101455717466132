import './GiftCardContent.scss';

const GiftCardCover = ({
  logoUrl,
  uniqueUrl = '#',
  trackerProps = {},
}) => (
  <a
    href={uniqueUrl}
    aria-label="merchant url"
    {...trackerProps}
  >
    <div className="GiftCardCover">
      <img
        className="GiftCardCover__logo"
        src={logoUrl}
        alt="brand logo"
      />
    </div>
  </a>
);

export default GiftCardCover;
